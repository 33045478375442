import {
  getTimeInAnchorTimeZone,
  guessTimeZone,
  updateToStartOfNextDayIfLastSlot,
  determineRBCEventEndWithEventStart,
  generateLargeRandomNumber,
  isAfterMinute,
  isBeforeMinute,
  IsEmptyObject,
  sortEventsJSDate,
  isSameOrAfterDay,
  isEmptyArray,
  convertToTimeZone,
  lowerCaseAndTrimString
} from "../services/commonUsefulFunctions";
import {
  parseISO,
  startOfMinute,
  isSameMinute,
  format,
  differenceInDays,
} from "date-fns";
import { SELECT_AVAILABILITY_COLOR } from "../services/globalVariables";
import { AVAILABILITY } from "../services/googleCalendarService";
import { ALL_DAY_EVENT_FORMAT_STRING } from "../components/groupVote/schedulingSharedVariables";
import {isEventSlotAllDayEvent} from "../lib/rbcFunctions";
import Fetcher from "../services/fetcher";

export const ACCEPTED = "accepted";
export const MAYBE = "maybe";

export function convertISOSlotsArrayToJSDate(slots, timeZone) {
  return slots.map((s) => {
    return convertISOSlotToJSDate(s, timeZone);
  });
}

export function convertISOSlotToJSDate(slot, timeZone) {
  // pass in string as iso string
  // need to return time in that time zone
  return {
    eventStart: getTimeInAnchorTimeZone(
      parseISO(slot.start),
      guessTimeZone(),
      timeZone
    ),
    eventEnd: getTimeInAnchorTimeZone(
      parseISO(slot.end),
      guessTimeZone(),
      timeZone
    ),
  };
}

export function createKeyFromSlot(slot, selectedTimeZone) {
  const { eventStart, eventEnd } = slot;
  if (isSameMinute(eventStart, eventEnd) 
    || isMultiDaySlot(slot) 
    || isEventSlotAllDayEvent(slot)
  ) {
    return `${format(eventStart, ALL_DAY_EVENT_FORMAT_STRING)}_${format(eventEnd, ALL_DAY_EVENT_FORMAT_STRING)}`;
  }

  const startOfMinuteEventStart = startOfMinute(eventStart);
  const startOfMinuteEventEnd = startOfMinute(eventEnd);

  if (selectedTimeZone && selectedTimeZone !== guessTimeZone()) {
    return `${getTimeInAnchorTimeZone(startOfMinuteEventStart, selectedTimeZone).toISOString()}_${getTimeInAnchorTimeZone(startOfMinuteEventEnd, selectedTimeZone).toISOString()}`;
  }
  
  return `${startOfMinuteEventStart.toISOString()}_${startOfMinuteEventEnd.toISOString()}`;
}

export function createKeyFromSlotISOString(slot) {
  const { 
    start, 
    end, 
    startDate, 
    endDate
  } = slot;
  if (startDate) {
    return `${fixAllDayStringFormat(startDate)}_${fixAllDayStringFormat(endDate)}`;
  }
  return `${start}_${end}`;
}

export function createTemporaryEvent({
  startTime,
  endTime,
  index,
  hideCancel,
}) {
  let timeEnd = updateToStartOfNextDayIfLastSlot(endTime);
  let rbcEventEnd = determineRBCEventEndWithEventStart(startTime, timeEnd);

  return {
    isTemporary: true,
    isAvailability: true,
    eventStart: startTime,
    index,
    eventEnd: timeEnd,
    rbcEventEnd: rbcEventEnd,
    backgroundColor: SELECT_AVAILABILITY_COLOR,
    raw_json: { status: AVAILABILITY },
    id: generateLargeRandomNumber(),
    hideCancel,
    isGroupVote: true,
    displayAsAllDay: isEventSlotAllDayEvent({
      eventStart: startTime,
      eventEnd: endTime,
    }),
  };
}

export function determineSlotAttendeeIndex(bookingLink) {
  // get which attendee clicked on which slot
  const attendees = getAttendees(bookingLink);
  let slotAttendeeIndex = {};

  attendees.forEach((a) => {
    if (isEmptyArray(a.slots)) {
      return;
    }

    a.slots.forEach((s) => {
      const convertedSlot = convertAttendeeSlotIntoISOString(s);
      const key = createKeyFromSlotISOString(convertedSlot);
      if (!slotAttendeeIndex[key]) {
        slotAttendeeIndex[key] = [getAttendeeNameAndEmailKey(a)];
      } else {
        slotAttendeeIndex[key] = slotAttendeeIndex[key].concat(getAttendeeNameAndEmailKey(a));
      }
    });
  });

  return slotAttendeeIndex;
}

export function determineSlotCriticalAttendeeIndex(bookingLink) {
  // get which attendee clicked on which slot
  const attendees = getAttendees(bookingLink);
  const criticalAttendees = getCriticalAttendees(bookingLink);
  let slotCriticalAttendeeIndex = {};

  attendees.filter((a) => criticalAttendees.includes(getObjectEmail(a))).forEach((a) => {
    if (isEmptyArray(a.slots)) {
      return;
    }

    a.slots.forEach((s) => {
      const key = createKeyFromSlotISOString(s);
      if (!slotCriticalAttendeeIndex[key]) {
        slotCriticalAttendeeIndex[key] = [getAttendeeNameAndEmailKey(a)];
      } else {
        slotCriticalAttendeeIndex[key] = slotCriticalAttendeeIndex[key].concat(getAttendeeNameAndEmailKey(a));
      }
    });
  });

  return slotCriticalAttendeeIndex;
}

export function getAttendeeNameAndEmailKey(attendee) {
  return `${attendee?.name ?? ""}_${attendee?.email ?? ""}`;
}

export function getSpreadsheetAttendees(groupVote) {
  if (!groupVote?.attendees) {
    return [];
  }

  const {
    attendees,
  } = groupVote;
  return attendees ?? [];
}

export function getAttendees(bookingLink) {
  if (!bookingLink?.attendees) {
    return [];
  }

  const {
    attendees
  } = bookingLink;

  return attendees.filter(a => a?.slots?.length > 0);
}

export function getInitialBookingLinkDay(bookingLink) {
  if (
    !bookingLink?.selected_slots ||
    bookingLink.selected_slots?.length === 0
  ) {
    return new Date();
  }

  const { selected_slots, time_zone } = bookingLink;
  const jsDateArray = convertISOSlotsArrayToJSDate(selected_slots, time_zone);
  const filteredArray = filterEventsInThePast(jsDateArray);
  let initialStartDate;
  filteredArray.forEach((s) => {
    if (!initialStartDate) {
      initialStartDate = s.eventStart;
    } else if (isBeforeMinute(s.eventStart, initialStartDate)) {
      initialStartDate = s.eventStart;
    }
  });

  return initialStartDate ?? new Date();
}

export function filterEventsInThePast(eventList) {
  if (!eventList || eventList.length === 0) {
    return [];
  }

  const NOW = new Date();
  return eventList.filter(e => (isEventSlotAllDayEvent(e) && isSameOrAfterDay(e.eventEnd, NOW)) || isAfterMinute(e.eventEnd, NOW));
}

export function getSlotWithMostNumberOfVotes(attendeesIndex, selectedSlots) {
  let mostPopularKey;
  const NOW = new Date();

  if (selectedSlots.length === 0) {
    // if no selected slots
    return { eventStart: null, eventEnd: null };
  } else if (IsEmptyObject(attendeesIndex)) {
    // no attendee has picked any slots -> choose first one that's
    return selectedSlots.find(
      (s) =>
        s.isAvailability &&
        isAfterMinute(s.eventStart, NOW) &&
        isAfterMinute(s.eventEnd, NOW)
    );
  }

  Object.keys(attendeesIndex).forEach((k) => {
    if (!mostPopularKey) {
      mostPopularKey = k;
    } else if (
      attendeesIndex[k]?.length > attendeesIndex[mostPopularKey]?.length
    ) {
      mostPopularKey = k;
    }
  });

  if (!mostPopularKey) {
    return { eventStart: null, eventEnd: null };
  }

  return getEventStartAndEndFromKey(mostPopularKey);
}

export function getEventStartAndEndFromKey(key) {
  if (!key) {
    return { eventStart: null, eventEnd: null };
  }

  let startEndArray = key.split("_");
  if (startEndArray.length <= 1) {
    return { eventStart: null, eventEnd: null };
  }

  return {
    eventStart: parseISO(startEndArray[0]),
    eventEnd: parseISO(startEndArray[1]),
  };
}

export function isSameSlot(slotA, slotB) {
  if (!slotA || !slotB) {
    return false;
  }
  if (slotA.startDate && slotB.startDate && slotA.endDate && slotB.endDate) {
    return slotA.startDate === slotB.startDate && slotA.endDate === slotB.endDate;
  }
  if (slotA.start && slotB.start && slotA.end && slotB.end) {
    return slotA.start === slotB.start && slotA.end === slotB.end;
  }
  return (
    isSameMinute(slotA.eventStart, slotB.eventStart) &&
    isSameMinute(slotA.eventEnd, slotB.eventEnd)
  );
}

export function isSlotInSelectSlots(slot, selectedSlots) {
  if (!slot.isAvailability) {
    return false;
  }

  return selectedSlots.some((s) => isSameSlot(slot, s));
}

export function parseEventsWithDefaultTimeZone(groupVoteLink, timeZone) {
  if (IsEmptyObject(groupVoteLink)) {
    return [];
  }
  const { selected_slots } = groupVoteLink;

  const selectedTimeZone = timeZone || guessTimeZone();

  /**
   * @param {string} timeString
   * @returns {Date}
   */
  const parseTimeString = (timeString) => {
    const time = parseISO(timeString);

    if (timeZone && timeZone !== guessTimeZone()) {
      return convertToTimeZone(time, { timeZone })
    } 
    
    return time;
  }

  const parsedSlots = selected_slots.map(s => {
    if (s.startDate && s.endDate) {
      return {
        eventStart: parseISO(fixAllDayStringFormat(s.startDate)),
        eventEnd: parseISO(fixAllDayStringFormat(s.endDate)),
        startDate: s.startDate,
        endDate: s.endDate,
      }
    }

    return {
      eventStart: parseTimeString(s.start),
      eventEnd: parseTimeString(s.end),
      start: s.start,
      end: s.end,
    };
  });

  const currentTime = convertToTimeZone(new Date(), {timeZone: selectedTimeZone}); // compare using the current time zone of the selected time zone
  return parsedSlots.filter(slot => isAfterMinute(slot.eventEnd, currentTime)); // filter out expired slots
}

export function sortSlotsChronologically(slots) {
  if (isEmptyArray(slots)) {
    return [];
  }

  return slots.sort((a, b) => sortEventsJSDate(a, b));
}

/**
 * At some times we were saving all day timestamps with the `yyyy-M-d` format.
 * Other times we were saving them with the `yyyy-MM-dd` format.
 * Since both have been persisted to the DB, let's handle both for now, but start
 * standardizing to `yyyy-MM-dd` since it's behavior is more consistent.
 *
 * @example
 * new Date("2025-09-30")
 * // => Mon Sep 29 2025 20:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-9-30")
 * // => Tue Sep 30 2025 00:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-10-01")
 * // => Tue Sep 30 2025 20:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-10-1")
 * // => Wed Oct 01 2025 00:00:00 GMT-0400 (Eastern Daylight Time)
 * new Date("2025-10-10")
 * // => Thu Oct 09 2025 20:00:00 GMT-0400 (Eastern Daylight Time)
 *
 * @param {string} dateString
 */
function fixAllDayStringFormat(dateString) {
  if (/^\d+-\d+-\d+$/.test(dateString)) {
    return dateString.split("-").map(part => part.padStart(2, "0")).join("-")
  }
  return dateString;
}

/**
 * @param {{ end: string, start: string } | { endDate: string, startDate: string }} attendeeSlot
 * @returns {{ end: string, start: string } | { endDate: string, startDate: string }}
 */
export function convertAttendeeSlotIntoISOString(attendeeSlot) {
  if (attendeeSlot.startDate && attendeeSlot.endDate) {
    const startDate = fixAllDayStringFormat(attendeeSlot.startDate)
    const endDate = fixAllDayStringFormat(attendeeSlot.endDate)
    return { startDate, endDate };
  }

  return {
    start: attendeeSlot.start,
    end: attendeeSlot.end,
  };
}

/**
 * @param {{ eventEnd: Date, eventStart: Date }} linkSlot
 * @param {string} timeZone
 * @returns {{ end: string, start: string } | { endDate: string, startDate: string }}
 */
function convertLinkSlotIntoISOString(linkSlot, timeZone) {
  const isAllDay = isEventSlotAllDayEvent(linkSlot);
  if (isAllDay) {
    return {
      startDate: format(linkSlot.eventStart, ALL_DAY_EVENT_FORMAT_STRING),
      endDate: format(linkSlot.eventEnd, ALL_DAY_EVENT_FORMAT_STRING),
    };
  }
  return {
    start: getTimeInAnchorTimeZone(linkSlot.eventStart, timeZone).toISOString(),
    end: getTimeInAnchorTimeZone(linkSlot.eventEnd, timeZone).toISOString(),
  };

}

export function convertSlotsIntoISOString(slots, timeZone) {
  if (isEmptyArray(slots)) {
    return [];
  }
  return slots.map((s) => convertLinkSlotIntoISOString(s, timeZone));
}

export function getNonExpiredSelectedSlotsWithDefaultTimeZone(groupVoteLink) {
  const jsDateArray = parseEventsWithDefaultTimeZone(groupVoteLink);
  return filterEventsInThePast(jsDateArray);
}

export async function loadFromUsernameAndSlug(url, propertyName) {
  const response = await Fetcher.get(url);
  if (!response || !!response.error) {
    return { response, token: null };
  }

  const property = response[propertyName];
  if (!property || !property.token) {
    throw `${propertyName} or token not found`; // this should never happen
  }

  return { response, token: property.token };
}

export function isMultiDaySlot(slot) {
  return differenceInDays(slot.eventEnd, slot.eventStart) > 1;
}

export function getObjectEmail(obj) {
  try {
    return lowerCaseAndTrimString(obj.email);
  } catch (e) {
    return null;
  }
}

export function getCriticalAttendees(bookingLink) {
  if (!bookingLink?.critical_attendees) {
    return [];
  }

  return bookingLink.critical_attendees;
}

export function determineIfAttendeeIsCritical({ criticalAttendees, email }) {
  if (isEmptyArray(criticalAttendees) || !email) {
    return false;
  }

  return criticalAttendees?.includes(lowerCaseAndTrimString(email));
}

export function sortAttendeeListByCritical({ attendeeList = [], criticalAttendees = [] }) {
  return attendeeList?.sort((a, b) => {
    const firstAttendeeEmail = getObjectEmail(a);
    const secondAttendeeEmail = getObjectEmail(b);
    const isFirstAttendeeCritical = determineIfAttendeeIsCritical({ criticalAttendees, email: firstAttendeeEmail });
    const isSecondAttendeeCritical = determineIfAttendeeIsCritical({ criticalAttendees, email: secondAttendeeEmail });

    /* Attendee A is critical */
    /* Attendee B is not critical */
    /* Sort A before B by returning a negative value */
    if (isFirstAttendeeCritical && !isSecondAttendeeCritical) {
      return -1;
    }

    /* Attendee A is not critical */
    /* Attendee B is critical */
    /* Sort A after B by returning a positive value */
    if (!isFirstAttendeeCritical && isSecondAttendeeCritical) {
      return 1;
    }

    /* For adding new row */
    if (firstAttendeeEmail && !secondAttendeeEmail) {
      return -1;
    }

    if (!firstAttendeeEmail && secondAttendeeEmail) {
      return 1;
    }

    /* Sort by email if both or neither are critical */
    return firstAttendeeEmail.localeCompare(secondAttendeeEmail);
  });
}
