import { differenceInMinutes, addMinutes, parseISO } from "date-fns";
import {
  isBeforeMinute,
  RoundToClosestMinuteJSDate,
  getTimeInAnchorTimeZone,
  guessTimeZone,
  shouldRoundToNearest15,
  // generateAvailabilityToken
} from "../../services/commonUsefulFunctions";

export const ACCEPTED = "accepted";
export const MAYBE = "maybe";
export const ALL_DAY_EVENT_FORMAT_STRING = "yyyy-MM-dd";
export const ALL_DAY_EVENT_FORMAT = { representation: "date" }; // e.g. formatISO(new Date(2019, 8, 18, 19, 0, 52), { representation: 'date' })

export const SORT_BY_CHRONOLOGICALLY = "SORT_BY_CHRONOLOGICALLY";
export const SORT_BY_VOTE_COUNT = "SORT_BY_VOTE_COUNT";
export const GOLD_STAR_HEX_CODE = "#C99E35";

export function splitAvailableTimesIntoDuration(slots, duration) {
  // slots come in as array of  {eventStart, eventEnd}
  if (!slots || slots.length === 0) {
    return [];
  } else if (!duration) {
    // if no duration, return slots as it is
    return slots;
  }

  const roundAndAddMinutes = (time) => {
    // if duration is 35 minutes -> round up to 30 and then add 35
    const roundUpTime = shouldRoundToNearest15(duration) ? 15 : 30;
    return RoundToClosestMinuteJSDate(
      addMinutes(time, roundUpTime),
      roundUpTime
    );
  };

  const chopUpSlots = (slot) => {
    const { eventStart, eventEnd } = slot;
    let choppedArray = [];
    const roundUpTime = shouldRoundToNearest15(duration) ? 15 : 30;
    let timeTracker = RoundToClosestMinuteJSDate(eventStart, roundUpTime);

    while (isBeforeMinute(timeTracker, eventEnd)) {
      choppedArray = choppedArray.concat({
        eventStart: timeTracker,
        eventEnd: addMinutes(timeTracker, duration),
      });
      timeTracker = roundAndAddMinutes(timeTracker);
    }

    return choppedArray;
  };

  let choppedUpSlots = [];

  slots.forEach((s) => {
    const { eventStart, eventEnd } = s;
    if (differenceInMinutes(eventEnd, eventStart) < duration) {
      return;
    }

    // chop up
    choppedUpSlots = choppedUpSlots.concat(chopUpSlots(s));
  });

  return choppedUpSlots;
}

export function convertISOSlotsArrayToJSDate(slots, timeZone) {
  return slots.map((s) => {
    return convertISOSlotToJSDate(s, timeZone);
  });
}

export function convertISOSlotToJSDate(slot, timeZone) {
  // pass in string as iso string
  // need to return time in that time zone
  const {
    startDate, // for all day events
    start,
    end,
  } = slot;
  if (startDate) {
    return {
      eventStart: parseISO(startDate),
      eventEnd: parseISO(startDate),
    };
  }
  return {
    eventStart: getTimeInAnchorTimeZone(
      parseISO(start),
      guessTimeZone(),
      timeZone
    ),
    eventEnd: getTimeInAnchorTimeZone(parseISO(end), guessTimeZone(), timeZone),
  };
}
