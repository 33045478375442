import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { getDefaultModalStyle, isSafari } from "../services/commonUsefulFunctions";
import { X } from "react-feather";

export default function EventModalPopup({
  children,
  contentLabel,
  height,
  isOpen,
  onRequestClose,
  style,
  width,
  ...headerProps
}) {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={style || getDefaultModalStyle()}
      contentLabel={contentLabel}
      ariaHideApp={false}
    >
      <div
        style={{ height, width }}
        className={isSafari() ? "" : "home-page-hide-scroll-bar-unless-hover"}
      >
        
        <Header
          {...headerProps}
          onRequestClose={onRequestClose}
        />
        {children}
      </div>
    </Modal>
  );
}

function Header({
  onRequestClose,
  title,
}) {

  return (
    <div
      className={classNames(
        "flex items-start justify-between",
        "mb-2.5",
      )}
    >
			<div
				className={classNames(
					"flex items-center justify-between",
					"w-full",
				)}
			>
				<div className="font-size-16 font-weight-400">{title}</div>
				<X
					onClick={onRequestClose}
					className="cursor-pointer hoverable-secondary-text-color"
					size={16}
				/>
			</div>
    </div>
  );
}
